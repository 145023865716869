import Flex from 'components/Flex/Flex';
import HeaderSimple from 'components/HeaderSimple/HeaderSimple';
import { BREAKPOINTS } from 'constants/breakpoints';
import { ARROW_POSITIONS, ARROW_TYPES } from 'constants/generic';
import { FontTag, FontType } from 'constants/styling';
import { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import Autoplay, { AutoplayOptionsType } from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import {
  Children,
  ReactNode,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { cn } from 'utils/cn';
import { clampSlidesObject } from 'utils/sliderUtils';
import {
  SlidesObject,
  StyledArrow,
  StyledArrowsWrapper,
  StyledDotsWrapper,
  StyledWrapper,
  StyledWrapperProps,
} from './SliderEmbla.styled';

/**
 * Props: showDots / showArrows
 * These elements are default set to none
 * To show it always pass {[BREAKPOINTS.XS]: true} to the prop
 * To show it when screen width is bigger than medium width pass f.e. {[BREAKPOINTS.MD]: true} to the prop
 */

/**
 * Props slidesToShow
 * Pass breakpoints as key and auto or a a number to show the amount of slides
 */

interface SliderEmblaProps extends StyledWrapperProps {
  autoplay?: boolean;
  autoplayOptions?: AutoplayOptionsType;
  children?: ReactNode;
  className?: string;
  id?: string;
  maxDots?: number;
  onScrollCallback?: (index: number) => void;
  onSelectCallback?: (index: number) => void;
  options?: EmblaOptionsType;
  progressBarClassName?: string;
  ref?: Ref<EmblaCarouselType>;
  shouldClampSlidesToAvailable?: boolean;
  showProgressBar?: boolean;
  subtitle?: string;
  subtitleTag?: FontTag;
  subtitleType?: FontType;
  title?: ReactNode;
  titleButton?: ReactNode;
  titleColor?: string;
  titleTag?: FontTag;
  titleType?: FontType;
}

const SliderEmbla = forwardRef<EmblaCarouselType, SliderEmblaProps>(
  (
    {
      arrowPosition = ARROW_POSITIONS.TOP,
      autoplay,
      autoplayOptions: autoplayOptionsProp,
      children,
      className,
      id,
      itemGap = 50,
      maxDots = 5,
      onScrollCallback,
      onSelectCallback,
      options: optionsProp,
      progressBarClassName,
      shouldClampSlidesToAvailable = true,
      showArrows: showArrowsProp,
      showArrowsOnHover,
      showDots,
      showProgressBar,
      slidesToShow = { [BREAKPOINTS.XS]: 'auto' },
      subtitle,
      subtitleTag = 'h3',
      subtitleType = 'p',
      title,
      titleButton,
      titleColor,
      titleTag = 'h2',
      titleType = 'h3',
    }: SliderEmblaProps,
    ref,
  ) => {
    const slides = Children.toArray(children);

    const options: EmblaOptionsType = {
      align: 'start',
      ...optionsProp,
    };

    const autoplayOptions: AutoplayOptionsType = {
      delay: 10000,
      ...autoplayOptionsProp,
    };

    const plugins = autoplay ? [Autoplay(autoplayOptions)] : [];

    const [emblaRef, emblaApi] = useEmblaCarousel(options, plugins);

    // @ts-ignore
    useImperativeHandle(ref, () => emblaApi);

    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
    const progressBarDisabled = scrollSnaps.length < 2;

    const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
    const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
    const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

    const onInit = useCallback((emblaApi: EmblaCarouselType) => {
      setScrollSnaps(emblaApi.scrollSnapList());
    }, []);

    const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
      const index = emblaApi.selectedScrollSnap();
      setSelectedIndex(index);
      if (onSelectCallback) {
        onSelectCallback(index);
      }
      setPrevBtnDisabled(!emblaApi.canScrollPrev());
      setNextBtnDisabled(!emblaApi.canScrollNext());
    }, []);

    const onScroll = useCallback((emblaApi: EmblaCarouselType) => {
      if (onScrollCallback) {
        const index = emblaApi.selectedScrollSnap();
        onScrollCallback(index);
      }
    }, []);

    useEffect(() => {
      if (!emblaApi) return;

      onInit(emblaApi);
      onSelect(emblaApi);

      emblaApi.on('reInit', onInit);
      emblaApi.on('reInit', onSelect);

      emblaApi.on('select', onSelect);

      emblaApi.on('scroll', onScroll);
    }, [emblaApi, onInit, onSelect]);

    const showArrows = useMemo(
      () => (prevBtnDisabled && nextBtnDisabled ? {} : showArrowsProp),
      [prevBtnDisabled, nextBtnDisabled, showArrowsProp],
    );

    const arrowPrev = (
      <StyledArrow
        type={ARROW_TYPES.PREV}
        onClick={scrollPrev}
        disabled={prevBtnDisabled}
        className="embla__button embla__button__prev z-base-overwrite"
        showArrows={showArrows}
        id="embla__button__prev"
      />
    );

    const arrowNext = (
      <StyledArrow
        type={ARROW_TYPES.NEXT}
        onClick={scrollNext}
        disabled={nextBtnDisabled}
        className="embla__button embla__button__next z-base-overwrite"
        showArrows={showArrows}
        id="embla__button__next"
      />
    );

    const hasTitle = title || subtitle;

    // Custom sort from mobile to desktop to make sure the breakpoints are in the right order
    /* eslint-disable sort-keys-fix/sort-keys-fix */
    const sortedSlidesToShow: SlidesObject = {
      xs: slidesToShow?.xs,
      sm: slidesToShow?.sm,
      md: slidesToShow?.md,
      lg: slidesToShow?.lg,
      xl: slidesToShow?.xl,
      xxl: slidesToShow?.xxl,
    };
    /* eslint-enable sort-keys-fix/sort-keys-fix */

    const calculatedSlidesToShow = shouldClampSlidesToAvailable
      ? clampSlidesObject(sortedSlidesToShow, slides?.length)
      : sortedSlidesToShow;

    const dotsLeftIndex = Math.max(selectedIndex + 1 - (maxDots - 1), 0);
    const dotsRightIndex = Math.min(dotsLeftIndex + maxDots, scrollSnaps.length);
    const dotsIsLastSelected = selectedIndex === scrollSnaps.length - 1;
    const dotsCorrectedLeftIndex = dotsIsLastSelected ? dotsLeftIndex - 1 : dotsLeftIndex;
    const dotsManyToTheLeft = dotsCorrectedLeftIndex > 0;
    const dotsManyToTheRight = dotsRightIndex < scrollSnaps.length;

    return (
      <StyledWrapper
        className={className}
        arrowPosition={arrowPosition}
        slidesToShow={calculatedSlidesToShow}
        showArrowsOnHover={showArrowsOnHover}
        showArrows={showArrows}
        itemGap={itemGap}
        id={id}
      >
        {(hasTitle || (showArrows && arrowPosition === ARROW_POSITIONS.TOP)) && (
          <Flex
            justifyContent={hasTitle ? 'space-between' : 'flex-end'}
            alignItems="center"
            fullWidth
            marginBottom={100}
          >
            {hasTitle && (
              <HeaderSimple
                marginBottom={subtitle ? 100 : 0}
                subtitle={subtitle}
                subtitleTag={subtitleTag}
                subtitleType={subtitleType}
                title={title}
                titleButton={titleButton}
                titleTag={titleTag}
                titleType={titleType}
                titleColor={titleColor}
              />
            )}

            {arrowPosition === ARROW_POSITIONS.TOP && (
              <StyledArrowsWrapper showArrows={showArrows}>
                {arrowPrev}
                {arrowNext}
              </StyledArrowsWrapper>
            )}
          </Flex>
        )}

        <div className="embla">
          <div
            className={`embla__viewport ${!!showArrows && Object.keys(showArrows).length ? 'showarrows' : 'donotshow'}`}
            ref={emblaRef}
          >
            <div className="embla__container">
              {slides.map((child, index) => (
                <div key={index} className={`embla__slide embla__slide__${index}`}>
                  {child}
                </div>
              ))}
            </div>
          </div>
          {arrowPosition === ARROW_POSITIONS.CENTER && (
            <>
              {arrowPrev}
              {arrowNext}
            </>
          )}
        </div>

        <StyledDotsWrapper className="flex h-4 w-full list-none items-center justify-center gap-2" showDots={showDots}>
          {scrollSnaps.map((_, index) => {
            if (index < dotsCorrectedLeftIndex) return null;
            if (index >= dotsRightIndex) return null;
            return (
              <li
                key={`dot-${index}`}
                role="button"
                onClick={() => scrollTo(index)}
                className={cn(
                  'size-2.5 rounded-full border-none p-0',
                  index === selectedIndex ? 'bg-primary-30' : 'bg-accent-40',
                  index !== selectedIndex && index === dotsCorrectedLeftIndex && dotsManyToTheLeft && 'size-1.5',
                  index !== selectedIndex && index === dotsRightIndex - 1 && dotsManyToTheRight && 'size-1.5',
                )}
              />
            );
          })}
        </StyledDotsWrapper>

        {showProgressBar && !progressBarDisabled && (
          <div
            className={cn(
              'relative mx-auto mt-4 flex h-2 overflow-hidden rounded bg-accent-40 md:w-1/2',
              progressBarClassName,
            )}
          >
            <div
              className="absolute h-2 rounded bg-primary-30 transition-all duration-500"
              style={{
                transform: `translate3d(${selectedIndex * 100}%,0px,0px)`,
                width: `${100 / scrollSnaps.length}%`,
              }}
            />
          </div>
        )}
      </StyledWrapper>
    );
  },
);

export default SliderEmbla;
